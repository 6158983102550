import axios from "axios";
import { getUser } from "../services/zitadelAuthService";

const axiosWithHeader = axios.create({
  baseURL: `${process.env.REACT_APP_API_PUBLIC_URL}`,
});

axiosWithHeader.interceptors.request.use(
  async (config) => {
    const user = await getUser();
    if (user && user?.profile?.email && !user.expired) {
      config.headers.Authorization = `Bearer ${user.id_token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosWithHeader;
