import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { getProductBatch } from "../api/dashboard";
import ProgressBarLoading from "../components/progressBarLoading";
import { PRODUCT_DASH_CONST } from "../constants/constant";

const ReviewPage = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();

  // states
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currProductIndex, setCurrProductIndex] = React.useState<number>(-1);
  const [currBatch, setCurrBatch] = React.useState<any[]>([]);

  // variables
  const currProductDetail = useMemo(
    () => currBatch[currProductIndex],
    [currBatch, currProductIndex]
  );

  // fetch function
  const fetchAndSetBatch = async () => {
    setLoading(true);
    try {
      const batchRes: any = await getProductBatch();
      setCurrBatch(batchRes.data?.products || []);
      setCurrProductIndex(batchRes.data?.itr || -1);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // hooks
  useEffect(() => {
    if (currProductIndex === -1) {
      // fetch product batch
      fetchAndSetBatch();
    }
    if (currProductIndex === currBatch.length - 1) {
      // complete the batch
      // fetchCurrBatch();
    }
  }, [currBatch.length, currProductIndex]);

  if (loading) return <ProgressBarLoading message={PRODUCT_DASH_CONST.LOADING_PRODUCT} />;
  return <div>ReviewPage {currProductIndex} {JSON.stringify(currProductDetail)}</div>;
};

export default ReviewPage;
