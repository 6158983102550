import { UserManager } from 'oidc-client-ts';
import { authConfig } from '../zitadelAuthConfig';

const userManager = new UserManager(authConfig as any);

export const signIn = async () => await userManager.signinRedirect();
export const signOut = async () => await userManager.signoutRedirect();
export const getUser = async () => await userManager.getUser();
export const signinRedirectCallback = () => userManager.signinRedirectCallback();

export default userManager;
