import React, { useEffect } from "react";
import { Button, Container, Text } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const Login: React.FC = () => {
  const { user, signIn } = useAuth();
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      await signIn();
    } catch (error) {}
  };

  useEffect(() => {
    if (user && user.email) {
      navigate("/dashboard");
    }
  }, [user, navigate]);
  return (
    <Container>
      <Text h1>Login</Text>
      <Button onClick={() => handleSignIn()}>Sign in</Button>
    </Container>
  );
};

export default Login;
