export const authConfig = {
  authority: `${process.env.REACT_APP_ZITADEL_PROJECT_URL}`, 
  client_id: `${process.env.REACT_APP_ZITADEL_CLIENT_ID}`,
  redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}/callback`,
  post_logout_redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}/login`,
  response_type: "code",
  scope: "openid profile email",
  response_mode: "query",
  code_challenge_method:"S256"
};
