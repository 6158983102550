import { Progress } from "@nextui-org/react";
import React from "react";

interface ProgressBarLoadingProps {
  message: string;
}

const ProgressBarLoading: React.FC<ProgressBarLoadingProps> = ({ message }) => {
  return (
    <div className="md-progress-bar-loading-wrapper">
      <div className="md-progress-bar-loading-data-container">
        <div>{message}</div>
        <Progress
          indeterminated
          size="sm"
          aria-label="Loading..."
          className="max-w-md"
        />
      </div>
    </div>
  );
};

export default ProgressBarLoading;
