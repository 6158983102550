export const PRODUCT_CARD = {
  KEYS: {
    NAME: "Product Name",
    MRP: "MRP",
    PDP_URL: "PDP url",
    BRAND: "Brand",
  },
  CARD: {
    HEIGHT: "324px",
    WIDTH: "268px",
  },
  IMAGE: {
    HEIGHT: 211,
    WIDTH: 226,
    ALT: "Product Card Image",
  },
  EXT_URL: {
    HEIGHT: 20,
    WIDTH: 20,
    ALT: "External Link",
  },
};

export const PRODUCT_DASH_CONST = {
  SOURCE_PRODUCT: "Source Product",
  PREVIOUS: "Previous",
  NEXT: `Next`,
  NOT_MATCH: "No Match Found (0)",
  MODAL: {
    TITLE: "Confirm Selection",
    SUB_TITLE: "Are you sure you want to submit this match?",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
    SKIP: "Skip",
    RELOAD: "Reload",
    CONFIRM_MSG: {
      NO_MATCH: 'Confirm "No Match Found" ?',
      MATCH: "Are you sure you want to match this product with",
      SKIP: "You haven’t selected a matching product. Skip and let the team lead review it later.",
    },
  },
  NO_PRODUCT: "No product available",
  LOADING_PRODUCT: "Loading product...",
  RETRY: "Retry",
  REVIEW_STATUS: {
    ACCEPT: "matched",
    REJECT: "no_match",
    SKIPPED: "skipped",
  },
  EVENT_TYPE: {
    KEYDOWN: "keydown",
  },
  KEYS: {
    ENTER: "Enter",
    ARROW_RIGHT: "ArrowRight",
    ARROW_LEFT: "ArrowLeft",
  },
  SNACKBAR: {
    TAG_INPROGRES: "Tagging Product...",
    TAGGED: "Product Tagged...",
    SKIP: "Product Skipped...",
    INFO: "info",
    SUCCESS: "success",
  },
  PRIMARY: "primary",
  ERROR: "error",
};

export const NUMBER_PRONUNCIATION = [
  "th",
  "st",
  "nd",
  "th",
  "th",
  "th",
  "th",
  "th",
  "th",
  "th",
];


export const TAGGER_NEXT_BUTTON_TOOLTIP_TEXT = "Click or press the right arrow key to view the next product";
export const TAGGER_PREV_BUTTON_TOOLTIP_TEXT = "Click or press the left arrow key to view the previous product";
export const BATCH_EXPIRED_API_KEY = "batch_expired";
export const FAILED_API_MSG = "Failed to tag product";