import React from "react";
import { Text } from "@nextui-org/react";
import '../styles/header.css';
import Profile from "./Profile";

export type User = {
    email: string | undefined;
};

const Header: React.FC<User> = ({
    email = '',
}) => {
    return (
        <div className='header'>
            <Text h3>Product Matching Dashboard</Text>
            <div className="user-info">
                <Text h4>Welcome, {email}</Text>
                <Profile />
            </div>
        </div>
    )
};

export { Header };