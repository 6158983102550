import React, { useMemo } from "react";
import { Container, Button, Text, Spacer } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();

  // permissions
  const canTag = useMemo(() => {
    if (!user || !user.role) return false;
    const canAccess = ["admin", "tagger", "reviewer"];
    return user.role.some((role: string) => canAccess.includes(role));
  }, [user]);
  const canReview = useMemo(() => {
    if (!user || !user.role) return false;
    const canAccess = ["admin", "reviewer"];
    return user.role.some((role: string) => canAccess.includes(role));
  }, [user]);

  // handlers
  const handleLogout = async () => {
    await signOut();
    navigate("/login");
  };

  const handleModeSelect = (selectedMode: "review" | "tag") => {
    navigate(`/dashboard/${selectedMode}`);
  };

  // JSX renders
  if (!user) {
    return (
      <Container>
        <Text color="error">User data not available. Please log in again.</Text>
        <Button onClick={() => navigate("/login")}>Go to Login</Button>
      </Container>
    );
  }

  return (
    <Container>
      <Text h3>Welcome, {user.email}</Text>
      <Text h5>Choose a mode:</Text>
      <Spacer y={1} />
      {canReview && <Button className="m-2" onClick={() => handleModeSelect("review")}>
        Review
      </Button>}
      <Spacer y={1} />
      {canTag && <Button className="m-2" onClick={() => handleModeSelect("tag")}>
        Tag
      </Button>}

      <Spacer y={1} />
      <Button onClick={handleLogout}>Logout</Button>
    </Container>
  );
};

export default Dashboard;
