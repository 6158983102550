import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signinRedirectCallback } from "../services/zitadelAuthService";
import ProgressBarLoading from "../components/progressBarLoading";
import "../styles/callback.css";

const Callback: React.FC = () => {
  const navigate = useNavigate();

  const handleSignInRedirectCallback = async () => {
    try {
      await signinRedirectCallback();
      navigate("/dashboard");
    } catch (error) {
      console.error("Authentication error:", error);
    }
  };

  useEffect(() => {
    handleSignInRedirectCallback();
  }, [navigate]);

  return (
    <ProgressBarLoading message="Fetching your profile..." />
  );
};

export default Callback;
