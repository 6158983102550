import React, { useState, useEffect, useCallback, memo, useRef } from "react";
import { Container, Button, Text, Modal } from "@nextui-org/react";
import { Header } from "./Header";
import ProductCard from "./ProductCard";
import { closeSnackbar, useSnackbar } from "notistack";

import {
  BATCH_EXPIRED_API_KEY,
  FAILED_API_MSG,
  NUMBER_PRONUNCIATION,
  PRODUCT_DASH_CONST,
  TAGGER_NEXT_BUTTON_TOOLTIP_TEXT,
  // TAGGER_PREV_BUTTON_TOOLTIP_TEXT,
} from "../constants/constant";
import {
  getProductBatch,
  removePreviousBatch,
  tagProduct,
} from "../api/dashboard";
import ProgressBarLoading from "./progressBarLoading";

import "../styles/productTagger.css";
import { Product } from "./ProductTaggerType";

interface User {
  email: string;
  [keyof: string]: any;
}

interface ProductTaggerProps {
  user: User | null;
}

const ProductTagger: React.FC<ProductTaggerProps> = memo(({ user }) => {
  //snackbar
  const { enqueueSnackbar } = useSnackbar();

  //states
  const [products, setProducts] = useState<Product[]>([]);
  const [currentProductNumber, setCurrentProductNumber] = useState<number>(0);
  const [selectedMatch, setSelectedMatch] = useState<number | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showReloadModal, setShowReloadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [productNumber, setProductNumber] = useState<number>(0);
  // const [productStatusMap, setProductStatusMap] = useState<Map<number, number>>(
  //   new Map()
  // );
  const batchRef = useRef<boolean | null>();

  // const initializeProductStatusMap = (productCount: number) => {
  //   const initialMap = new Map<number, number>(
  //     Array.from({ length: productCount }, (_, i) => [i, 0])
  //   );

  //   setProductStatusMap(initialMap);
  // };

  const loadProduct = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const productsBatch: any = await getProductBatch();
      if (productsBatch?.data?.products) {
        setProducts(productsBatch?.data?.products);
        setCurrentProductNumber(productsBatch?.data?.itr || 0);
        setSelectedMatch(null);
        setProductNumber(productsBatch?.data?.itr || 0);
        // initializeProductStatusMap(productsBatch?.data?.products?.length);
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!batchRef.current) {
      loadProduct();
      batchRef.current = true;
    }
  }, [loadProduct]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (showConfirmation || showReloadModal) {
        return;
      }
      const key = parseInt(event.key);
      if (
        !isNaN(key) &&
        key >= 1 &&
        key <= products[currentProductNumber]?.matches?.length
      ) {
        setSelectedMatch(key - 1);
        setShowConfirmation(true);
      } else if (key === 0) {
        setSelectedMatch(-1);
        setShowConfirmation(true);
      }
    };

    window.addEventListener(
      PRODUCT_DASH_CONST.EVENT_TYPE.KEYDOWN,
      handleKeyPress as EventListener
    );
    return () => {
      window.removeEventListener(
        PRODUCT_DASH_CONST.EVENT_TYPE.KEYDOWN,
        handleKeyPress as EventListener
      );
    };
  }, [products, currentProductNumber, showConfirmation, showReloadModal]);

  const showSnackbar = useCallback(
    (displayText: string, variant: any) => {
      return enqueueSnackbar(displayText, {
        variant: variant,
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    },
    [enqueueSnackbar]
  );

  // const updateProductStatusMap = useCallback(
  //   (productIndex: number, status: number) => {
  //     setProductStatusMap((prevMap) => {
  //       prevMap.set(productIndex, status);
  //       return new Map(prevMap);
  //     });
  //   },
  //   []
  // );

  const handleMatchSelect = async (matchId: number) => {
    setSelectedMatch(matchId);
    setShowConfirmation(true);
  };

  const closeClickHandler = () => {
    setShowConfirmation(false);
    // setSelectedMatch(null);
  };

  const handleTagProduct = useCallback(
    async (status: string) => {
      if (products) {
        setLoader(true);
        setError(null);
        setShowConfirmation(false);
        const target_product_id =
          selectedMatch != null
            ? products[currentProductNumber]?.matches[selectedMatch]
                ?.match_product_id
            : "";
        try {
          let inprogress: any;
          if (status !== PRODUCT_DASH_CONST.REVIEW_STATUS.SKIPPED) {
            inprogress = showSnackbar(
              PRODUCT_DASH_CONST.SNACKBAR.TAG_INPROGRES,
              PRODUCT_DASH_CONST.SNACKBAR.INFO
            );
          }
          const tagPayload = {
            source_product_id:
              products[currentProductNumber]?.source_product_id || "",
            target_product_id: target_product_id || "",
            status: status || "",
          };
          const tagProductRes: any = await tagProduct(tagPayload).then(
            (data) => {
              if (status !== PRODUCT_DASH_CONST.REVIEW_STATUS.SKIPPED) {
                closeSnackbar(inprogress);
                showSnackbar(
                  PRODUCT_DASH_CONST.SNACKBAR.TAGGED,
                  PRODUCT_DASH_CONST.SNACKBAR.SUCCESS
                );
              } else {
                showSnackbar(
                  PRODUCT_DASH_CONST.SNACKBAR.SKIP,
                  PRODUCT_DASH_CONST.SNACKBAR.INFO
                );
              }
              setLoader(false);
              return data;
            }
          );
          if (tagProductRes.status === 200) {
            // updateProductStatusMap(currentProductNumber, 1);
            if (currentProductNumber === products?.length - 1) {
              await removePreviousBatch();
              await loadProduct();
            } else {
              setCurrentProductNumber(currentProductNumber + 1);
              setSelectedMatch(null);
              setProductNumber(currentProductNumber + 1);
            }
          }
        } catch (error: any) {
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data.msg === BATCH_EXPIRED_API_KEY
          ) {
            setShowReloadModal(true);
          } else {
            setError(FAILED_API_MSG);
          }
        } finally {
          setLoading(false);
        }
      }
    },
    [
      currentProductNumber,
      products,
      selectedMatch,
      showSnackbar,
      loadProduct,
      // updateProductStatusMap,
    ]
  );

  const handleKeyPressInModal = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === PRODUCT_DASH_CONST.KEYS.ENTER) {
        if (showReloadModal) {
          window.location.reload();
        } else if (showConfirmation) {
          handleTagProduct(
            selectedMatch === null
              ? PRODUCT_DASH_CONST.REVIEW_STATUS.SKIPPED
              : selectedMatch === -1
              ? PRODUCT_DASH_CONST.REVIEW_STATUS.REJECT
              : PRODUCT_DASH_CONST.REVIEW_STATUS.ACCEPT
          );
        }
      }
    },
    [handleTagProduct, selectedMatch,  showConfirmation, showReloadModal]
  );

  // const handlePreviousClick = useCallback(() => {
  //   if (
  //     currentProductNumber > 0 &&
  //     productNumber !== currentProductNumber + 2
  //   ) {
  //     setCurrentProductNumber(currentProductNumber - 1);
  //     setSelectedMatch(null);
  //   }
  // }, [currentProductNumber, productNumber]);

  const handleNextClick = useCallback(async () => {
    setSelectedMatch(null);
    setShowConfirmation(true);

    if (currentProductNumber === products?.length - 1) {
      await removePreviousBatch();
      await loadProduct();
      return;
    }

    // if (productStatusMap.get(currentProductNumber) === 0) {
    //   setShowConfirmation(true);
    //   // await handleTagProduct(PRODUCT_DASH_CONST.REVIEW_STATUS.SKIPPED);
    // } else {
    //   setProductNumber(currentProductNumber + 1);
    //   setCurrentProductNumber((prev) => prev + 1);
    //   setSelectedMatch(null);
    // }
  }, [
    currentProductNumber,
    loadProduct,
    products,
    // productStatusMap,
  ]);

  useEffect(() => {
    if (showConfirmation || showReloadModal) {
      window.addEventListener(
        PRODUCT_DASH_CONST.EVENT_TYPE.KEYDOWN,
        handleKeyPressInModal as EventListener
      );
    } else {
      window.removeEventListener(
        PRODUCT_DASH_CONST.EVENT_TYPE.KEYDOWN,
        handleKeyPressInModal as EventListener
      );
    }

    return () => {
      window.removeEventListener(
        PRODUCT_DASH_CONST.EVENT_TYPE.KEYDOWN,
        handleKeyPressInModal as EventListener
      );
    };
  }, [
    showConfirmation,
    showReloadModal,
    setShowConfirmation,
    handleKeyPressInModal,
  ]);

  useEffect(() => {
    const handleArrowKeyPress = (event: KeyboardEvent) => {
      if (showConfirmation || showReloadModal) {
        return;
      }
      if (event.key === PRODUCT_DASH_CONST.KEYS.ARROW_RIGHT) {
        handleNextClick();
      } else if (
        event.key === PRODUCT_DASH_CONST.KEYS.ARROW_LEFT &&
        currentProductNumber !== 0
      ) {
        // handlePreviousClick();
      }
    };

    window.addEventListener(
      PRODUCT_DASH_CONST.EVENT_TYPE.KEYDOWN,
      handleArrowKeyPress as EventListener
    );
    return () => {
      window.removeEventListener(
        PRODUCT_DASH_CONST.EVENT_TYPE.KEYDOWN,
        handleArrowKeyPress as EventListener
      );
    };
  }, [
    currentProductNumber,
    showReloadModal,
    handleNextClick,
    showConfirmation,
  ]);

  if (loading) {
    return <ProgressBarLoading message={PRODUCT_DASH_CONST.LOADING_PRODUCT} />;
  }

  if (error) {
    return (
      <Container>
        <Text color="error">{error}</Text>
        <Button onPress={loadProduct}>{PRODUCT_DASH_CONST.RETRY}</Button>
      </Container>
    );
  }

  if (!products?.length) {
    return <Text>{PRODUCT_DASH_CONST.NO_PRODUCT}</Text>;
  }

  return (
    <>
      <Header email={user?.name?.split(" ")?.[0] || user?.email} />
      <>
        {products && products?.length > 0 && (
          <div className="product-tagger">
            <div className="md-product-tagger-source-product-container">
              <div className="md-product-tagger-source-product-card">
                <ProductCard
                  imgURL={
                    products[currentProductNumber]?.source_feature_image_s3
                  }
                  name={products[currentProductNumber]?.source_product_name}
                  mrp={products[currentProductNumber]?.source_mrp?.INR}
                  pdpURL={products[currentProductNumber]?.source_pdp_url}
                  brand={products[currentProductNumber]?.source_brand}
                />
              </div>
              <Text h5 className="md-product-tagger-source-product">
                {PRODUCT_DASH_CONST.SOURCE_PRODUCT}
              </Text>

              <div className="button-section">
                <div className="prev-next-buttons">
                  {/* Not using previous for this relase */}
                  {/* <Button
                    bordered={true}
                    color="primary"
                    onPress={handlePreviousClick}
                    disabled={
                      currentProductNumber === 0 ||
                      productNumber === currentProductNumber + 2
                    }
                    title={TAGGER_PREV_BUTTON_TOOLTIP_TEXT}
                  >
                    {PRODUCT_DASH_CONST.PREVIOUS}
                  </Button> */}
                  <Button
                    color="primary"
                    onPress={handleNextClick}
                    title={TAGGER_NEXT_BUTTON_TOOLTIP_TEXT}
                  >
                    {PRODUCT_DASH_CONST.NEXT} (&rarr;)
                  </Button>
                </div>
                <div className="no-match-found-button">
                  <Button
                    color="error"
                    onPress={() => {
                      setSelectedMatch(-1);
                      setShowConfirmation(true);
                    }}
                  >
                    {PRODUCT_DASH_CONST.NOT_MATCH}
                  </Button>
                </div>
              </div>

              <div className="md-product-tagger-checklist-section">
                <Text h5>Checklist</Text>
                <ul
                  style={{ listStyleType: "disc" }}
                  className="md-product-tagger-text-style"
                >
                  <li>MRP should be the same</li>
                  <li>
                    Visually 100% similar
                    <ul style={{ listStyleType: "disc" }}>
                      <li>Color</li>
                      <li>Silhouette</li>
                    </ul>
                  </li>
                  <li>
                    Match Product should be in stock (if multiple matches are
                    present)
                  </li>
                </ul>
              </div>
            </div>

            <div className="matching-options">
              <div className="md-product-tagger-match-cards">
                {products[currentProductNumber]?.matches.map(
                  (data: any, index: number) => (
                    <ProductCard
                      key={index}
                      imgURL={data?.match_feature_image_s3}
                      name={data?.match_product_name}
                      mrp={data?.match_mrp?.INR}
                      serialNumber={index + 1}
                      pdpURL={data?.match_pdp_url}
                      brand={data?.match_brand}
                      onProductCardExtClick={() => handleMatchSelect(index)}
                      highlighted={selectedMatch === index}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </>
      <Modal
        open={showReloadModal}
        preventClose={true}
      >
        <Modal.Header>
          <Text h3>Match Results Expired</Text>
        </Modal.Header>
        <Modal.Body>
          <Text b>
            Your last {productNumber} match results have expired. Please reload
            the page to refresh your data and start again
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button
            auto
            onPress={() => {
              window.location.reload();
            }}
          >
            {PRODUCT_DASH_CONST.MODAL.RELOAD}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showConfirmation}
        onClose={() => {
          closeClickHandler();
        }}
      >
        <Modal.Header>
          <Text h3>{PRODUCT_DASH_CONST.MODAL.TITLE}</Text>
        </Modal.Header>
        <Modal.Body>
          <Text b>
            {selectedMatch === null
              ? PRODUCT_DASH_CONST.MODAL.CONFIRM_MSG.SKIP
              : selectedMatch === -1
              ? PRODUCT_DASH_CONST.MODAL.CONFIRM_MSG.NO_MATCH
              : `${PRODUCT_DASH_CONST.MODAL.CONFIRM_MSG.MATCH} ${
                  selectedMatch + 1
                }${NUMBER_PRONUNCIATION[selectedMatch + 1 || 0]} Product?`}
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button
            auto
            flat
            color="error"
            onPress={() => {
              closeClickHandler();
            }}
          >
            {PRODUCT_DASH_CONST.MODAL.CANCEL}
          </Button>
          <Button
            auto
            onPress={() => {
              handleTagProduct(
                selectedMatch === null
                  ? PRODUCT_DASH_CONST.REVIEW_STATUS.SKIPPED
                  : selectedMatch === -1
                  ? PRODUCT_DASH_CONST.REVIEW_STATUS.REJECT
                  : PRODUCT_DASH_CONST.REVIEW_STATUS.ACCEPT
              );
            }}
          >
            {selectedMatch === null ? PRODUCT_DASH_CONST.MODAL.SKIP : PRODUCT_DASH_CONST.MODAL.CONFIRM}
          </Button>
        </Modal.Footer>
      </Modal>

      {loader ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            zIndex: "300",
            top: 0,
            background: "rgba(0,0,0,0.3)",
          }}
        ></div>
      ) : (
        ""
      )}
    </>
  );
});

export default ProductTagger;
