import React from "react";
import { useAuth } from "../context/AuthContext";
import ProductTagger from "../components/ProductTagger";

// will remove it and add it to review page if complexity is not high
const TagPage = () => {
  // fetch product id from url
  const { user } = useAuth();
  return <ProductTagger user={user} />;
};

export default TagPage;
