import React from "react";
import { Dropdown, Image } from "@nextui-org/react";
import profileIcon from "../assets/svg/profileIcon.svg";
import { signOut } from "../services/zitadelAuthService";
import { useNavigate } from 'react-router-dom';

const LOGOUT_KEY = "logout";
const Profile: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
    navigate('/login');
  };

  return (
    <Dropdown>
      <Dropdown.Button flat>
        <Image src={profileIcon} />
      </Dropdown.Button>
      <Dropdown.Menu
        aria-label="User Actions"
        onAction={(key) => key === LOGOUT_KEY && handleLogout()}
      >
        <Dropdown.Item key={LOGOUT_KEY} color="error">
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Profile;
