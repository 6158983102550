/*
 * File: Dashboard.tsx
 * Project: matching-dashboard
 * File Created: Wednesday, 4th September 2024 3:16:37 pm
 * Author: Sharath Puranik (sharath.puranik@stylumia.com)
 * -----
 * Last Modified: Monday, 16th September 2024 4:53:55 pm
 * Modified By: Sharath Puranik (sharath.puranik@stylumia.com>)
 * -----
 * Copyright - 2024, Stylumia Intelligence Technology Pvt Ltd
 */
import React, { useState } from "react";
// import { Container, Button, Text, Spacer } from "@nextui-org/react";
import { Card, Text, Image, Container, Link } from "@nextui-org/react";
import NewTabIcon from "../../assets/svg/newTabIcon.svg";
import ImagePlaceholder from "../../assets/svg/imagePlaceHolder.svg";
import { PRODUCT_CARD } from "../../constants/constant";

import "./productCard.css";

interface ProductCardProps {
  imgURL: string;
  name: string;
  mrp: string | number;
  pdpURL: string;
  brand: string;
  serialNumber?: number;
  highlighted?: boolean;
  onProductCardExtClick?: () => void;
}
const ProductCard: React.FC<ProductCardProps> = ({
  imgURL,
  name,
  mrp,
  pdpURL,
  brand,
  serialNumber,
  highlighted = false,
  onProductCardExtClick,
}) => {
  const productCardClickHandler = () => {
    if (onProductCardExtClick) {
      onProductCardExtClick();
    }
  };
  return (
    <div className="product-card" onClick={productCardClickHandler}>
      <Card
        style={{
          height: PRODUCT_CARD.CARD.HEIGHT,
          width: PRODUCT_CARD.CARD.WIDTH,
        }}
        className={`md-product-card-container ${
          highlighted && " md-product-card-highlighted"
        }`}
      >
        <div className="md-product-card-image-data-container">
          <div className="md-product-card-image-container">
            <Image
              alt={PRODUCT_CARD.IMAGE.ALT}
              className="md-product-card-image"
              src={imgURL || ImagePlaceholder}
            />
            {(serialNumber === 0 || serialNumber) && (
              <div className="md-product-card-serial-number">
                {serialNumber}
              </div>
            )}
          </div>
          <div>
            <Text className="md-product-card-keys-style">
              {PRODUCT_CARD.KEYS.NAME} -
              <Text
                span
                b
                className="md-product-card-values-style"
                title={name}
              >
                {name?.length > 14 ? `${name?.slice(0, 14)}...` : name}
              </Text>
            </Text>
            <Text className="md-product-card-keys-style">
              {PRODUCT_CARD.KEYS.MRP} -
              <Text span b className="md-product-card-values-style">
                {mrp ? `₹${mrp}` : ""}
              </Text>
            </Text>
            {brand && (
              <Text className="md-product-card-keys-style">
                {PRODUCT_CARD.KEYS.BRAND} -
                <Text
                  span
                  b
                  className="md-product-card-values-style"
                  title={brand}
                >
                  {brand?.length > 14 ? `${brand?.slice(0, 14)}...` : brand}
                </Text>
              </Text>
            )}
            <div className="md-product-card-ext-link" title={pdpURL}>
              <Link
                href={pdpURL || ""}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    alt={PRODUCT_CARD.EXT_URL.ALT}
                    className="md-product-card-values-style"
                    height={PRODUCT_CARD.EXT_URL.HEIGHT}
                    src={NewTabIcon}
                    width={PRODUCT_CARD.EXT_URL.WIDTH}
                  />
                  <Text className="md-product-card-keys-style">
                    - {pdpURL ? `${pdpURL.slice(0, 20)}...` : ""}
                  </Text>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ProductCard;
