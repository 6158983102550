import axios, { AxiosError } from "axios";
import Products from "./data.json";
import axiosWithHeader from "./interceptors";
import { useAuth } from "../context/AuthContext";

interface Product {
  product_id: string;
  category_id: string;
  channel_id: string;
  source_image_url: string;
  matches: Match[];
}

interface Match {
  product_id: string;
  category_id: string;
  channel_id: string;
  target_image_url: string;
  score: number;
}

export const getProductBatch = async () => {
  try {
    const products = await axiosWithHeader.get(
      `${process.env.REACT_APP_API_PUBLIC_URL}/api/tagging/get_batch`
    );
    return products;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      throw new Error(`Failed to fetch product: ${axiosError.message}`);
    }
    throw new Error("Failed to fetch product");
  }
};

export const tagProduct = async (body = {}) => {
  try {
    const res = await axiosWithHeader.post("/api/tagging/tag_product", body);
    return res;
  } catch (error: any) {
    throw error;
  }
};

export const removePreviousBatch = async () => {
  try {
    await axiosWithHeader.post(
      `${process.env.REACT_APP_API_PUBLIC_URL}/api/tagging/complete_batch`
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const axiosError = e as AxiosError;
      throw new Error(`Failed to remove previous batch: ${axiosError.message}`);
    }
    throw new Error("Failed to remove previous batch");
  }
};

// export const fetchProduct = async (
//   page: number,
//   userEmail: string
// ): Promise<{ product: Product; totalPages: number }> => {
//   try {
//     const response = await axiosWithHeader.get<{
//       product: Product;
//       totalPages: number;
//     }>(`${process.env.REACT_APP_API_PUBLIC_URL}/product`, {
//       params: { page, userEmail },
//     });
//     return response.data;
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       const axiosError = error as AxiosError;
//       throw new Error(`Failed to fetch product: ${axiosError.message}`);
//     }
//     throw new Error("Failed to fetch product");
//   }
// };

// export const submitTag = async (
//   productId: string,
//   matchId: string,
//   userEmail: string
// ): Promise<void> => {
//   try {
//     await axiosWithHeader.post(
//       `${process.env.REACT_APP_API_PUBLIC_URL}/tagging/tag`,
//       {
//         productId,
//         matchId,
//         userEmail,
//       }
//     );
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       const axiosError = error as AxiosError;
//       throw new Error(`Failed to submit tag: ${axiosError.message}`);
//     }
//     throw new Error("Failed to submit tag");
//   }
// };
