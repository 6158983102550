import React, {
  createContext,
  useEffect,
  useState,
  ReactNode,
  useContext,
} from "react";
import { getUser, signIn, signOut } from "../services/zitadelAuthService";

interface AuthContextType {
  loading: boolean;
  user: UserType | null;
  signIn: (...args: any) => Promise<void>;
  signOut: () => Promise<void>;
}

interface UserType {
  name: string;
  email: string;
  [key: string]: any;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const getUserDetail = async () => {
    setLoading(true);
    const userResponse = await getUser();
    const role = userResponse?.profile?.["urn:zitadel:iam:org:project:roles"];
    const currentUser: any = {
      user_id: userResponse?.profile?.sub,
      name: userResponse?.profile?.name,
      email: userResponse?.profile?.email,
      jwt_token: userResponse?.id_token,
    };
    if (role) {
      currentUser["role"] = Object.keys(role);
    }
    if (currentUser && currentUser.email) {
      setUser(currentUser);
      setLoading(false);
      return currentUser;
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!user) {
      // waiting for zitadel server updates
      setTimeout(() => getUserDetail(), 2000);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        signIn: signIn,
        signOut: signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
